import $ from 'jquery';


$('.landing-select__product').on('click', function() {

    const hiddenInput = $('[name="product_selection"]');
    const fixedProducts = $('[data-sku-fixed]');
    

    if(hiddenInput.length != 1) {
        return false;
    }

    let products = fixedProducts.data('skuFixed');

    if($(this).hasClass('landing-select__product--selected')) {
        $(this).removeClass('landing-select__product--selected');
    }
    
    else if($('.landing-select__product--selected').length == 3) {
        alert("Bitte max drei Produkte auswählen. Sie können ein anderes Produkt auch wieder abwählen.")
    }
    else {
        $(this).addClass('landing-select__product--selected');
    }

    $('.landing-select__product--selected').each(function() {
        products = products + ', ' + $(this).data('sku');
    });

    hiddenInput.val(products);

    console.log(hiddenInput.val());
});

$('#FormBuilder_gerl_gutschein').on('submit', function() {

    if($('.landing-select__product--selected').length < 3) {
        var c = confirm("Sie haben weniger als 3 Produkte ausgewählt, wollen Sie Ihre Bestellung trotzdem abschicken?");
        return c; //you can just return c because it will be true or false
    }

});