import algoliasearch from 'algoliasearch/lite';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';

const searchField = document.querySelector('#search');

if(searchField) {

    const searchClient = algoliasearch(
        searchField.dataset.appId,
        searchField.dataset.publicApiKey
    );

    const autocompleteSearch = autocomplete({
        container: '#search',
        placeholder: 'Suchbegriff oder Artikelnummer',
        onSubmit({state}) {
            if(state.query !== '') {
                location.href = '/suche/?search=' + state.query;
            }
        },
        getSources() {
            const getIndexName = () => {
                return searchField.getAttribute('data-index');
            };

            return [
                {
                    sourceId: 'querySuggestions',
                    getItemInputValue: ({ item }) => item.query,
                    getItemUrl({item, state,}) {
                        return item.url
                    },
                    getItems({ query }) {
                        return getAlgoliaResults({
                            searchClient,
                            queries: [
                                {
                                    indexName: getIndexName(),
                                    query,
                                    params: {
                                        hitsPerPage: 10,
                                    },
                                },
                            ],
                        });
                    },
                    templates: {
                        item({ item, html }) {

                            if(item.type === 'group') {
                                let variants = '';

                                if(item.variants.length > 1) {
                                    variants = item.variants.length + ' Produkte';
                                }
                                else {
                                    variants = '1 Produkt';
                                }

                                return html`<a href="${item.url}"><img src="${item.image}" /> <span>${item.name}</span><small>${variants}</small></a>`;
                            }

                            if(item.type === 'variant') {
                                return html`<a href="${item.url}"><img src="${item.image}" /> <span>${item.name}</span><small>Artikelnr. ${item.sku}</small></a>`;
                            }

                        },
                    },
                },
            ];
        },
    });

}