import Splide from "@splidejs/splide";
import { Video } from '@splidejs/splide-extension-video';
import '@splidejs/splide/css';
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';

const productSlidesBg = document.querySelector('.productSlidesBg');
const productSlider = document.querySelector('.productSlider');

if(productSlider !== null && productSlidesBg !== null) {

    const slider = new Splide('.productSlider', {
        type   : 'loop',
        perPage: 1,
        arrows: false,
        pagination: true,
        autoplay: false,
        autoHeight: true,
        lazyLoad: 'sequential',

    }).mount({ Video } );

    window.addEventListener('resize', () => {
        setSliderBgHeight();
    });

    slider.on('lazyload:loaded', () => {
        setSliderBgHeight();
    });

}

function setSliderBgHeight() {
    const height = productSlider.querySelector('.is-active').offsetHeight;
    const top = document.querySelector('header').offsetHeight;
    productSlidesBg.style.height = height + 'px';
    productSlidesBg.style.top = top + 'px';
}

