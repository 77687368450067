import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import '@splidejs/splide/css';

if(document.querySelector('.splide--depots') !== null) {

new Splide('.splide--depots', {
    type   : 'loop',
    perPage: 4,
    arrows: false,
    pagination: false,
    gap: '6rem',
    autoWidth: true,
    autoScroll: {
        speed: 2,
    },

    }).mount({ AutoScroll });
}